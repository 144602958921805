<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col-12 col-md-6">
        <h1>Gerar certificados do treinamento</h1>
        <h6>Selecione pas pessoas que você deseja gerar o certificado</h6>
      </div>
      <div class="col-12 col-md-6 text-right align-self-end">
        <div class="form-group d-flex float-right">
          <label class="align-self-end mr-2">Gerar com template de fundo?</label>
          <select class="form-control w-25" v-model="certificadoFuncionarios.template">
            <option value="n">Não</option>
            <option value="s">Sim</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <th><Checkbox @checkOption="checkAll" :valueProp="'0'" style="margin-top:-20px;"></Checkbox></th>
              <th>Nome</th>
              <th>CPF</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in funcionarios" :key="index">
              <td scope="row">
                <Checkbox @checkOption="checkItem" :valueProp="index" :checkProp="isCheckedAll(item.funcionario_id)"></Checkbox>
              </td>
              <td>{{item.funcionario.nome}}</td>
              <td>{{item.funcionario.cpf}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-primary btn-dark-color btn-hover" @click="gerarCertificados">
          Gerar certificados
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import treinamentoRepository from './../../../services/api/treinamentoRepository'
import {OpenWindowWithPost} from './../../../services/windowPost.service'
import constants from './../../../../constants'
import Checkbox from './../../../uicomponents/Checkbox'
export default {
  components: {
    Checkbox
  },
  created () {
    if (this.$route.query.treinamento) {
      this.fetch(this.$route.query.treinamento)
      this.certificadoFuncionarios.treinamento_id = this.$route.query.treinamento
    }
  },
  data () {
    return {
      isLoading: false,
      funcionarios: [],
      certificadoFuncionarios: {
        template: 'n',
        treinamento_id: null,
        idFuncionarios: []
      }      
    }
  },
  methods: {
    fetch (idTreinamento) {
      treinamentoRepository.buscaTreinamentoFuncionariosPorTreinamentoId(idTreinamento).then(response => {
        if(response.data['success']) {
          this.funcionarios = response.data['data']
        }
      })
    },
    isCheckedAll(funcionario_id) {
      return this.certificadoFuncionarios.idFuncionarios.includes(funcionario_id)
    },
    checkItem(isChecked, listPosition) {
      let funcionarioId = this.funcionarios[listPosition].funcionario_id
      if(isChecked) {
        this.certificadoFuncionarios.idFuncionarios.push(funcionarioId)
      } else {
        let index = this.certificadoFuncionarios.idFuncionarios.indexOf(funcionarioId);
        this.certificadoFuncionarios.idFuncionarios.splice(index, 1);
      }
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Funcionarios' })
    },
    checkAll(isChecked) {
      this.certificadoFuncionarios.idFuncionarios = []
      if(isChecked) {
        this.funcionarios.map( obj => {
          this.certificadoFuncionarios.idFuncionarios.push(obj.funcionario_id)
        })
      }
    },
    gerarCertificados () {
      if(this.certificadoFuncionarios.idFuncionarios.length > 0) {
        let param = this.certificadoFuncionarios
        this.goBack()
        OpenWindowWithPost(`${constants.API_URL}/impressao/certificado`, "", "Certificado", param);
      } else {
        this.$swal({
          icon: 'warning',
          text: 'Favor selecionar pelo menos uma pessoa!'
        })
      }
      
    }
  }
}
</script>

<style>

</style>